<template>
    <div class='createBox'>
        <div class="headerBox">
            <!-- <a href="https://ai.cemeta.cenmetahome.cn/"> -->

            <img @click="toPP" src="../assets/image/logo.jpg" alt="">
            <!-- </a> -->

        </div>
        <div class="centent">
            <div class="goFort">
                <button @click="router.go(-1)">
                    <el-icon>
                        <ArrowLeft />
                    </el-icon>
                    <span>
                        返回
                    </span>
                </button>
            </div>

            <p style="margin-top:20px ;">
                更新时间：2023年8月28日
            </p>
            <p>
                生效时间：2023年8月28日
            </p>
            <div>
                <p>
                    1. CeMeta旗下产品内容，是指CeMeta旗下产品所创作、生成的AI内容，包括但不限于基于模型生成的文案、图片、动画和音视频素材。其所述产品包括但不限于CeMeta旗下的CeMeta AI营销文案助手、
                    CeMeta AI商品图助手、 CeMeta AI商品视频 平台
                </p>
                <p>
                    2.
                    您在使用本服务时发布上传的内容均应为您原创或已获合法授权（且含转授权），上传内容的知识产权或相关合法权益归您或您享有合法使用权，同时您应确保上传内容不侵犯任何人的知识产权、名誉权、姓名权、隐私权、商业秘密等合法权益，否则由此产生的侵权风险和责任由您承担，相应纠纷应由您负责解决，并且您应赔偿我们由此遭受的全部直接、间接损失（包括但不限于经济、商誉、维权支出、律师费等损失）。同时，对于您发布上传的内容，您授予CeMeta平台一项无偿、无需标注您个人身份的、在法律允许范围内合理且最大程度的权益，允许我们使用您的内容向您提供服务、改进和提升我们的服务，用来提升我们的算法服务。如果您不愿意向我们提供前述授权的，请您立即停止使用我们的服务。
                </p>
                <p>3.
                    CeMeta旗下产品所创作AI内容，其版权与最终解释权归属CeMeta所有。本服务以及因使用本服务所取得的任何产出或成果，仅限您本人用于学术测试目的，未经CeMeta平台事前书面同意，您不得将其所创作内容用于任何商业或其他目的与用途，且不得自行或透过他人以任何方式或载体向第三方披露、提供、转发、传播或公开。
                </p>
                <p>
                    4.
                    CeMeta所创作的AI内容属于人工智能深度合成内容，将严格遵守国家《互联网信息服务深度合成管理规定》所示规定。同时，您理解并同意，本服务尚存在不完备性，我方不对任何服务可用性、可靠性做出任何承诺。我方不对您使用本服务或其产出成果承担任何责任，且本服务结果不代表我方立场，请您谨慎使用。
                </p>
                <p>
                    5. 在任何情况下，CeMeta平台均不就因本服务所发生的任何直接性、间接性、后果性、惩戒性、偶然性、特殊性的损害
                    (包括但不限于：您使用本服务而遭受的利润损失)，承担任何责任(即使您已事先被告知该等损害发生的可能性)。如因您在使用中发生任何争议或不当言论，其责任由您自行承担。
                </p>
                <p>
                    6.
                    对于您使用CeMeta平台，上传、提交、发布、存储、发送、接收、传播或分享的内容，我们仅在有关法律、法规、国家政策等规定或者相关国家机关的要求的前提下，自行或委托第三方进行查看、审阅、分析、讨论等，并有权将该等内容提供给相关国家机关审阅并用于其所需的目的。我们自行或委托第三方对该等内容进行查看、审阅、分析、讨论的行为，不代表我们对该等内容承担任何审查或保证责任，亦不豁免您因违反该条约定而需承担的本协议约定的违约责任。
                </p>
                <p>
                    7.
                    您理解并且同意，为持续改善CeMeta平台为您提供的各项服务，除非有相反证明，您使用CeMeta平台服务上传、发布或传输内容即代表了您有权且同意在全世界范围内，永久性的、不可撤销的、免费的授予CeMeta平台及其关联方对该内容的存储、使用、发布、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利；将内容的全部或部分编入其他任何形式的作品、媒体、技术中的权利；对您的上传、发布的内容进行商业开发的权利；通过有线或无线网络向计算机终端、移动通讯终端（包括但不限于便携式通讯设备如手机和智能平板电脑等）、手持数字影音播放设备、电视接收设备（模拟信号接收设备、数字信号接收设备、数字电视、IPTV、电视机机顶盒、视频投影仪、车载电视、互联网电视/OTT终端、智能电视等带互联网接入功能的播放设备等）、智能音响等智能硬件终端等提供信息的下载、点播、语音播放、数据传输、音频业务、移动视频业务（包括但不限于SMS、MMS、WAP、IVR、Streaming、手机视频等无线服务）及其相关宣传和推广等服务的权利；以及再授权给其他第三方以上述方式使用的权利。
                </p>
                <p>
                    8. 通过CeMeta旗下产品授权使用的单位，不应超越其与CeMeta平台所属方签署的具体的授权范围。
                </p>
                <p>
                    9. 如因作品内容、版权和其它问题需要同本网站联系的，请您在知悉该作品确有争议后30日内联系本网站，并提供相应的联系方式、权属证明、涉嫌侵权内容及其在网站上的位置等信息，以便我们查明情况并帮助您维权。
                </p>
                <p>CeMeta平台提示您注意：如果权利通知的陈述失实，权利通知提交者将承担对由此造成的全部法律责任（包括但不限于赔偿各种费用及律师费）。如果上述个人或单位不确定其是否侵犯了其合法权益，平台建议您首先咨询专业人士。
                </p>


            </div>


        </div>

    </div>
</template>
<script setup>
import router from '@/router';
import { useStore } from 'vuex';
const store = useStore();
const toPP = () => {
    store.commit('comShow/upDataShow', 'Excess');
    router.push('excess');

};

</script>
<style lang='less'>
.createBox {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 999999;
    background: url('../assets/image/背景.jpeg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .headerBox {
        height: 8%;
        width: 100%;

        img {
            width: 2.9333rem;
        }
    }

    .centent {
        width: 65%;
        background: white;
        border-radius: 10px;
        padding: 39px;
        box-shadow: 0 7px 27px #0000004f;
        height: 90%;
        overflow: auto;
        position: relative;

        p {
            font-size: .32rem;
            line-height: .5333rem;
        }

        div {
            p {
                text-indent: 2em;
            }
        }
    }

    .goFort {
        position: absolute;
        left: 20px;
        top: 20px;

        font-size: 1.0667rem;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: transparent;
            border: none;

            span {
                margin: 0 .08rem;
                font-size: .3733rem;
            }

            i {
                font-size: .4533rem;
            }
        }
    }

}

@media only screen and (min-width:1692px) {

    .centent {
        width: 45% !important;

    }

    .goFort {
        left: 20% !important;
    }

    .createBox {
        background-size: 100% 100%;
    }
}
</style>
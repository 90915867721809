<template>
  <div class='poneBox'>
    <div class="headerBox">
      <p>
        <a href="https://ai.cemeta.cenmetahome.cn/">
        <img  src="../assets/image/logo.jpg" alt="">
        </a>
      </p>
      <p>AI 商品视频助手</p>
      <p>
        <button @click="clickTy">立即体验</button>
      </p>
    </div>
    <div class="body">
      <div class="title">
        <p>仅需三步</p>
        <p>即可发布大量商品视频</p>
      </div>
      <div class="dlBox">
        <dl>
          <dt>
            <img src="https://cemetaxcx-pic-1313090634.cos.ap-beijing.myqcloud.com/AIvideoproduct/gif/step1.gif
" alt="">
          </dt>
          <dd>
            <h6>第一步：建立商品AI视频模型</h6>
            <p>上传5-10条商品拍摄视频</p>
            <p>输入商品信息</p>
            <p>即可完成商品AI视频模型构建</p>
          </dd>
        </dl>

        <dl>
          <dt>
            <img src="https://cemetaxcx-pic-1313090634.cos.ap-beijing.myqcloud.com/AIvideoproduct/gif/step2.gif
" alt="">
          </dt>
          <dd>
            <h6>第二步：生成大量商品视频</h6>
         
            <p>选择生成数量</p>
            <p>等待视频生成</p>
          </dd>
        </dl>
        <dl>
          <dt>
            <img src="https://cemetaxcx-pic-1313090634.cos.ap-beijing.myqcloud.com/AIvideoproduct/gif/step3.gif" alt="">
          </dt>
          <dd>
            <h6>第三步：生成平台发布文案</h6>
            <p>点击生成视频</p>
            <p>匹配发布文案</p>
            <p>实现一键发布</p>
          </dd>
        </dl>
      </div>
      <div class="btnBox">
        <button @click="clickTy">
          立即体验
        </button>
      </div>
    </div>
    <div class="createBoxOne">
      <el-dialog width="80%" v-model="isCopy">
        <h1>
          创建大量商品推广视频
        </h1>
        <p>
          目前仅开放电脑端体验
        </p>
        <div class="imgBox">
          <img src="../assets//image/pcImg.jpg" alt="">
        </div>
        <div class="btnBox">
          <button @click="copyUrl">
            复制网址链接
          </button>
        </div>

      </el-dialog>
    </div>
    <div class="copySusses">
      <el-dialog width="80%" v-model="copyAlt">
<h6>
  <span>复制成功</span>
  <el-icon><SuccessFilled /></el-icon>
</h6>
<p>打开电脑浏览器</p>
<p>创作更多商品推广视频吧</p>
      </el-dialog>
    </div>

  </div>
</template>
<script setup>
import useClipboard from 'vue-clipboard3';

import { ref } from 'vue';
const isCopy = ref(false);
const copyAlt = ref(false);
const timer=ref(null);

const clickTy=()=>{
    isCopy.value=true;
};
const copyUrl=()=>{
    isCopy.value=false;
    copyAlt.value=true;
    copy('aivideo.cenmetahome.cn');
};

// 使用插件
const { toClipboard } = useClipboard();
const copy = async (msg) => {
    timer.value = null;
    try {
    // 复制
        await toClipboard(msg);
        // 复制成功
        // ElMessage({
        //     message: '复制成功',
        //     type: 'success'
        // });
    } catch (e) {
    // 复制失败
        // ElMessage({
        //     message: '复制失败',
        //     type: 'error'
        // });

    }
};

</script>
<style lang='less'>
button{
   cursor: pointer;
}
.poneBox {
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 99999999;
  position: relative;
  display: flex;
  flex-direction: column;

  .headerBox {
    height: 9vh;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: .32rem;
    padding: 0 .2667rem;

    p:nth-child(1) {
      img {
        width: 2.6667rem;
      }
    }

    p:nth-child(2) {
      font-size: .4533rem;
    }

    p:nth-child(3) {
      button {
        background: linear-gradient(to right, #85a0f8, #d681f8);
        border: 0;
        color: white;
        border-radius: 6px;
        padding: .1067rem .2667rem;
      }
    }

  }

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      text-align: center;
      font-weight: 500;
      font-size: .5333rem;
      line-height: .88rem;
      margin-top: .48rem;
    }

    .dlBox {
      padding: .2667rem;

      width: 100%;

      dl {
        width: 100%;
        display: flex;
        border: 1px solid #ccc;
        margin: .1333rem 0;
        padding: .2667rem .4rem;
        border-radius: 8px;

        dt {
          width: 30%;
          height: 2.9333rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        dd {
          flex: 1;
          background: #ebebeb;
          display: flex;
          align-items: center;
          flex-direction: column;
          border-radius: 8px;

          h6 {
            margin-top: .32rem;
            font-size: .3733rem;
          }

          p {
            font-size: .2667rem;
            margin: .08rem 0;
          }
        }
      }
    }

    .btnBox {
      padding-top: .32rem;

      button {
        background: linear-gradient(to right, #85a0f8, #d681f8);
        color: white;
        width: 37vw;
        height: 1.2rem;
        border: 0;
        border-radius: 5px;
      }
    }
  }

  .createBoxOne {
    .imgBox {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      color: #000;
      margin: .2667rem 0;
    }

    h1 {
      font-size: .5333rem;
      color: #000;
      margin: .2667rem 0;
    }

    i {
      font-size: 25px;
    }

    text-align: center;
    width: 80%;

    img {
      width: 80%;
    }

    .el-dialog {
      border-radius: 10px !important;

    }

    .el-overlay-dialog {
      top: 100px !important;
      border-radius: 10px !important;
    }

    .btnBox {
      margin: .8rem 0 .2667rem 0;

      button {
        background: linear-gradient(to right, #85a0f8, #d681f8);
        color: white;
        border: 0;
        border-radius: 20px;
        padding: .2667rem .4267rem;
      }
    }
  }
  .copySusses{
    h6{
      display: flex;
      align-items: center;
      justify-content: center;
    }
     i{
      font-size: .6667rem;
    }
        .el-dialog {
      border-radius: 10px !important;
    }
    .el-overlay-dialog {
      top: 100px !important;
      border-radius: 10px !important;
    }

    text-align: center;
    h6{
      margin: .5333rem 0;
      font-size: .4267rem;
    color: #000;
    span{
      margin: 0 .2667rem;
    }

    }
    p{
      font-size: .3733rem;
      line-height: .8rem;
      color: #000;
      font-weight: 500;

    }
  }
}</style>
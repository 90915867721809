import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import ExcessView from '../views/ExcessView.vue';
import ErrorView from '../views/ErrorView.vue';
import MinView from '../views/PhoneView.vue';
import CreateView from '../views/CreateView.vue';
import store from '../store';

const router = createRouter({
    history: process.env.NODE_ENV === 'development' ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/home',
            name: 'home',
            component: HomeView,
            meta: {
                AuthRouter: true
            }
        },
        {
            path: '/phone',
            name: 'phone',
            component: MinView,
            meta: {
                AuthRouter: true
            }
        },
        {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import('../views/AboutView.vue'),
            meta: {
                AuthRouter: true
            }
        },
        {
            path: '/',
            name: 'login',
            component: LoginView

        }, {
            path: '/excess',
            name: 'excess',
            component: ExcessView,
            meta: {
                AuthRouter: true
            }
        },
        {
            path: '/create',
            name: 'create',
            component: CreateView

        },
        {
            path: '/:catchAll(.*)',
            name: 'NotFound',
            component: ErrorView
        }
    ]
});

router.beforeEach((to, from, next) => {
    const token = store.state.user.token;
    const width = document.body.clientWidth;

    if(width<450){
        if(to.path==='/phone'){
            //移动端尺寸
            next();
        }else{
            next('/phone');

        }

    }else{
        // 检查路由元信息中是否要求认证
        if (to.meta.AuthRouter && !token) {
            next('/');
        } else {
            // 否则，继续导航
            next();
        }
    }

});


export default router;

import './assets/main.css';
import '../src/assets/icon.css';
import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import store from './store';
import 'lib-flexible/flexible.js';
// import VueClipboard from 'vue-clipboard3';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
// app.use(VueClipboard);

app.use(ElementPlus);
app.use(router);
app.use(store);

app.mount('#app');

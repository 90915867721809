<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class='animationBox'>

        <!-- <div class="bigBox">
            <div></div>
            <div></div>
        </div>
        <div class="text">
            Ai
        </div> -->
        <img src="../../assets/image/gifimg.gif" alt="">
    </div>
</template>
<script setup>
</script>
<style lang='less'>
.animationBox {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
img{
    width: 130px;
    height: 130px;
}
    // .bigBox {
    //     width: 200px;
    //     height: 200px;
    //     border-radius: 50%;
    //     background: linear-gradient(to bottom, rgb(235, 44, 210), #8b0c94, #fff);
    //     padding: 10px;
    //     position: relative;
    //     animation: rotate 1s linear infinite;
    //     z-index: 0;

    //     div:nth-child(1) {
    //         width: 100%;
    //         height: 100%;
    //         border-radius: 50%;
    //         background: #F2F2F9;
    //     }

    //     div:nth-child(2) {
    //         width: 50%;
    //         height: 100%;
    //         position: absolute;
    //         right: 0;
    //         bottom: 0;
    //         background: #F2F2F9;

    //     }
    // }

    // .text {
    //     font-size: 100px;
    //     font-weight: 800;
    //     width: 100px;
    //     height: 100px;
    //     text-align: center;
    //     line-height: 100px;
    //     position: absolute;
    //     bottom: 50%;
    //     margin-bottom: -100px;
    //     background-image: linear-gradient(to bottom, rgb(235, 44, 210), #8b0c94, #fff);
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //     background-clip: text;
    //     text-fill-color: transparent;

    // }

    // @keyframes rotate {
    //     0% {
    //         transform: rotate(0);
    //     }

    //     100% {
    //         transform: rotate(360deg);
    //     }
    // }
}</style>